import t from "../helpers/calculator_translations";

interface CalculatorConfigGroup {
    [locale: string]: CalculatorConfig
}

export interface CalculatorConfig {
    domainId: number;
    vatRate: number,
    showPriceInclVat: boolean;
    wordsOptions: { [key: number]: string };
    estimateUrl: string,
}

export const CalculatorConfigGroup = (locale:string) => {
    let wordsOptions = {
        5000: "0 – 5000",
        10000: "5001 – 10000",
        15000: "10001+",
    };

    let config: CalculatorConfigGroup = {
        'nl': {
            domainId: 1,
            vatRate: 21,
            showPriceInclVat: false,
            wordsOptions: wordsOptions,

            estimateUrl: t('calculator.estimate_url'),
        },
        'de': {
            domainId: 2,
            vatRate: 19,
            showPriceInclVat: true,
            wordsOptions: wordsOptions,

            estimateUrl: t('calculator.estimate_url'),
        },
        'nl-BE': {
            domainId: 3,
            vatRate: 21,
            showPriceInclVat: false,
            wordsOptions: wordsOptions,

            estimateUrl: t('calculator.estimate_url'),
        },
        'en': {
            domainId: 4,
            vatRate: 21,
            showPriceInclVat: false,
            wordsOptions: wordsOptions,

            estimateUrl: t('calculator.estimate_url'),
        },
        'de-AT': {
            domainId: 5,
            vatRate: 19,
            showPriceInclVat: true,
            wordsOptions: wordsOptions,

            estimateUrl: t('calculator.estimate_url'),
        }
    }

    return config[locale];
}