import React from "react";
import {ThreeDots} from "react-loader-spinner";
import classNames from "classnames";

// @ts-ignore
export function CalculatorLoading ({ color }) {
    return <div
        className={ classNames("position-absolute", "bg-" + color) }
        style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100px',
        }}
    >
        <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#FF585B"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
        />
    </div>
}