import React, {useEffect} from "react"

// @ts-ignore
import t from "../helpers/calculator_translations";

interface LanguageSelectorProps {
    name: string;
    locale: string,
    action: (value: string) => void;
    value?: string
}

interface Language {
    key: string;
    value: string;
}
export const LanguageSelector = ({locale, name, action, value}: LanguageSelectorProps): JSX.Element => {

    const label = t(`calculator.${name}.placeholder`);
    const popularLanguages = [
        'de','en','es', 'it', 'fr','nl',
    ]
    const languages = [
        'ar','az','bg','bn','bs','ca','cs','da','de','el','en','en_US','es','et','fa','fi','fil','fr','fr_BE','fy','ga','he','hi','hr','hu','hy','id','is','it','ja','ka','kk','kn','ko','ku','lb','lt','lv','mk','mn','ms','mt','nl','nl_BE','nn','pl','prs','ps','pt','pt_BR','ro','ru','sk','sl','so','sq','sr','sr_ME','sv','sw','ta','th','ti','tk','tr','uk','ur','uz','vi','zh','zh_TW',
    ]

    const sortedTranslatedLanguages = (languages: string[]): Language[] => {
        return languages
            .map(function (value) {
                return {
                    key: value,
                    value: t('language.' + value),
                };
            })
            .sort(function (a, b) {
                return a.value.localeCompare(b.value);
            });
    }

    return  <select
        className={"custom-select form-control"}
        required
        onChange={ (e) => {
            action(e.target.value)
        }}
        style={{ height: "auto", cursor: "pointer"}}
        value={value}
    >
        <option value="">{label}</option>
        <optgroup label={ t('calculator.language_top_combinations') }>
            { sortedTranslatedLanguages(popularLanguages).map(function (language) {
                return <option key={language.key} value={language.key}>{language.value}</option>
            }) }
        </optgroup>
        <optgroup label={ t('calculator.language_all_combinations') }>
            { sortedTranslatedLanguages(languages).map(function (language) {
                return <option key={language.key} value={language.key}>{language.value}</option>
            }) }
        </optgroup>
    </select>
}