import { Window } from "../interfaces/Window.model"

const warned: string[] = [];

export default (i: string, def?: string, param?: {[key: string]: any}) => {
    if (typeof def === "object") {
        param = def;
        def = undefined;
    }
    const template =
        (window as Window).calculatorTranslations?.[i] || (def !== undefined ? def : i);

    if (template === i && warned.indexOf(i) < 0) {
        warned.push(i);
        console.log(`Missing translation: ${i}`);
    }

    return template.replace(/\{([a-z0-9A-Z_]+)}/g, (substr, key) => {
        return param?.[key] === undefined
            ? substr
            : param[key] === null
                ? ""
                : param[key];
    });
};
