import React, {useEffect, useRef} from "react"
import {VbpPopover} from "../app";

interface InfoPopupProps {
    text: string;
    children?: JSX.Element;
}


export const InfoPopup = ( {text, children}: InfoPopupProps ): JSX.Element => {
    const popoverRef = useRef(null)
    const popover = useRef<VbpPopover|null>(null);

    const initPopover = () => {
        if (popover.current) {
            return;
        }

        if (popoverRef.current) {
            popover.current = new VbpPopover(popoverRef.current, {
                html: true,
                content: text,
                placement: "top",
                trigger: "focus",
            });
        }
    }

    useEffect(() => {
        initPopover();

        return () => {
            if (popover.current) {
                popover.current?.dispose();
                popover.current = null;
            }
        }
    })

    if (children) {
        let newChildren = React.cloneElement(children, {
            tabIndex: 0,
            ref: popoverRef,
        });

        return <>
            {newChildren}
        </>
    }

    return <i
        ref={popoverRef}
        className="fa fa-info-circle ms-2"
        style={{
            cursor: "pointer",
        }}
        tabIndex={0}
    />
}
