import React, {useEffect} from "react"

// @ts-ignore
import t from "../helpers/calculator_translations";

interface DisciplineSelectorProps {
    discipline: number|null;
    action: (value: number) => void;
}
export const DisciplineSelector = ({discipline, action}: DisciplineSelectorProps): JSX.Element => {
    // const [selected, setSelected] = useState(null);
    const disciplines = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,20];

    useEffect(() => {
        // action(disciplines[0]);
    }, []);

    return  <select
        className={"form-control custom-select"}
        required
        onChange={ (e) => {
            action(parseInt(e.target.value))
        }}
        style={{ height: "auto", cursor: "pointer"}}
        value={discipline ?? ''}
    >
        <option value="">{t("calculator.disciplines.placeholder")}</option>
        {
            disciplines.map((key) => {
                return (
                    <option key={key} value={key}>{ t('discipline.' + key) }</option>
                )
            })
        }
    </select>
}